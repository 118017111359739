// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-techtunes-ads-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-ads.js" /* webpackChunkName: "component---src-pages-techtunes-ads-js" */),
  "component---src-pages-techtunes-cocktail-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-cocktail.js" /* webpackChunkName: "component---src-pages-techtunes-cocktail-js" */),
  "component---src-pages-techtunes-credential-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-credential.js" /* webpackChunkName: "component---src-pages-techtunes-credential-js" */),
  "component---src-pages-techtunes-drive-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-drive.js" /* webpackChunkName: "component---src-pages-techtunes-drive-js" */),
  "component---src-pages-techtunes-electron-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-electron.js" /* webpackChunkName: "component---src-pages-techtunes-electron-js" */),
  "component---src-pages-techtunes-invest-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-invest.js" /* webpackChunkName: "component---src-pages-techtunes-invest-js" */),
  "component---src-pages-techtunes-jargon-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-jargon.js" /* webpackChunkName: "component---src-pages-techtunes-jargon-js" */),
  "component---src-pages-techtunes-magnet-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-magnet.js" /* webpackChunkName: "component---src-pages-techtunes-magnet-js" */),
  "component---src-pages-techtunes-siphon-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-siphon.js" /* webpackChunkName: "component---src-pages-techtunes-siphon-js" */),
  "component---src-pages-techtunes-slash-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-slash.js" /* webpackChunkName: "component---src-pages-techtunes-slash-js" */),
  "component---src-pages-techtunes-sms-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-sms.js" /* webpackChunkName: "component---src-pages-techtunes-sms-js" */),
  "component---src-pages-techtunes-sparrow-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-sparrow.js" /* webpackChunkName: "component---src-pages-techtunes-sparrow-js" */),
  "component---src-pages-techtunes-spary-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-spary.js" /* webpackChunkName: "component---src-pages-techtunes-spary-js" */),
  "component---src-pages-techtunes-transcribe-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-transcribe.js" /* webpackChunkName: "component---src-pages-techtunes-transcribe-js" */),
  "component---src-pages-techtunes-tycoon-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-tycoon.js" /* webpackChunkName: "component---src-pages-techtunes-tycoon-js" */),
  "component---src-pages-techtunes-zillion-js": () => import("D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\src\\pages\\techtunes-zillion.js" /* webpackChunkName: "component---src-pages-techtunes-zillion-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\techtunes-digital-ops\\techtunes-kaizen\\techtunes-kaizen-site\\.cache\\data.json")

